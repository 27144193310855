<template>
    <div
        id="share_modal"
        class="modal fade share-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="share_modalLabel"
        aria-hidden="true">
        <div
            class="modal-dialog"
            role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">__("seo_share_page")</h5>
                    <button
                        type="button"
                        class="close"
                        :class="this.currentLocale === 'ur' ? 'ml-20-neg' : ''"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body social">
                    <a
                        :href="getWhatsAppUri"
                        class="whatsapp"
                        :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                        target="_blank"
                        rel="noopener"
                        @click="triggerWaShareEvent">
                        <span class="social-icon"><icon
                            name="whatsapp"
                            scale="1.1"/></span>
                        <span class="text">WhatsApp</span>
                    </a>
                    <a
                        :href="getFacebookShareUrl"
                        class="facebook"
                        :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                        target="_blank"
                        rel="noopener"
                        @click="triggerFbShareEvent">
                        <span class="social-icon"><icon
                            name="facebook-f"
                            scale="1.1"/></span>
                        <span class="text">Facebook</span>
                    </a>
                    <a
                        class="link"
                        :dir="this.currentLocale === 'ur' ? 'ltr' : ''"
                        @click="copyUrlToClipboard">
                        <span class="social-icon"><icon
                            name="link"
                            scale="1.1"/></span>
                        <span class="text">Copy Link</span>
                    </a>
                    <input
                        id="shareUri"
                        :value="getContentUri+'?utm_campaign=Shared&utm_source=Link'"
                        type="hidden">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import 'vue-awesome/icons/facebook-f';
    import 'vue-awesome/icons/whatsapp';
    import 'vue-awesome/icons/link';

    import { mapActions, mapGetters } from 'vuex';
    import mixins from '@/mixins';

    export default {
        name: 'ShareModal',
        computed: {
            ...mapGetters([
                'getFacebookShareUrl',
                'getTwitterUrl',
                'getGooglePlusUrl',
                'getWhatsAppUri',
                'getContentUri',
                'getScreenDetails',
                'getUserDetails',
                'getPratilipiData'
            ])
        },
        mixins: [
            mixins
        ],
        data() {
            return {
                currentLocale: '',
            }
        },
        methods: {
            copyUrlToClipboard () {
                let pratilipiAnalyticsData = {};
                if (this.getPratilipiData) {
                    pratilipiAnalyticsData = this.getPratilipiAnalyticsData(this.getPratilipiData);
                }
                const action = !this.getPratilipiData ? 'SHAREUSERLINK' : 'SHAREBOOKLINK';
                this.triggerAnanlyticsEvent(`${action}_${this.getScreenDetails.screen_location}_${this.getScreenDetails.screen_name}`, 'CONTROL', {
                    ...pratilipiAnalyticsData,
                    'USER_ID': this.getUserDetails.userId,
                    'ENTITY_VALUE': 'LINK',
                    'PARENT_ID': this.$route.params[this.$route.meta.id_prop]
                });

                const shareUrl = document.querySelector('#shareUri');
                shareUrl.setAttribute('type', 'text');
                shareUrl.select();

                try {
                   
                    const successful = document.execCommand('copy');
                    const msg = successful ? 'successful' : 'unsuccessful';
                    console.log('Copying share url was ' + msg);
                    this.triggerAlert({ message: '__("success_generic_message")', timer: 3000 });
                } catch (err) {
                    console.log('Oops, unable to copy');
                }

                /* unselect the range */
                shareUrl.setAttribute('type', 'hidden');
                window.getSelection().removeAllRanges();
            },
            triggerFbShareEvent () {
                let pratilipiAnalyticsData = {};
                if (this.getPratilipiData) {
                    pratilipiAnalyticsData = this.getPratilipiAnalyticsData(this.getPratilipiData);
                }
                const action = !this.getPratilipiData ? 'SHAREUSERFB' : 'SHAREBOOKFB';
                this.triggerAnanlyticsEvent(`${action}_${this.getScreenDetails.screen_location}_${this.getScreenDetails.screen_name}`, 'CONTROL', {
                    ...pratilipiAnalyticsData,
                    'USER_ID': this.getUserDetails.userId,
                    'ENTITY_VALUE': 'FACEBOOK',
                    'PARENT_ID': this.$route.params[this.$route.meta.id_prop]
                });
            },
            triggerGpShareEvent () {
                let pratilipiAnalyticsData = {};
                if (this.getPratilipiData) {
                    pratilipiAnalyticsData = this.getPratilipiAnalyticsData(this.getPratilipiData);
                }
                const action = !this.getPratilipiData ? 'SHAREUSERGP' : 'SHAREBOOKGP';
                this.triggerAnanlyticsEvent(`${action}_${this.getScreenDetails.screen_location}_${this.getScreenDetails.screen_name}`, 'CONTROL', {
                    ...pratilipiAnalyticsData,
                    'USER_ID': this.getUserDetails.userId,
                    'ENTITY_VALUE': 'GOOGLEPLUS',
                    'PARENT_ID': this.$route.params[this.$route.meta.id_prop]
                });
            },
            triggerTwShareEvent () {
                let pratilipiAnalyticsData = {};
                if (this.getPratilipiData) {
                    pratilipiAnalyticsData = this.getPratilipiAnalyticsData(this.getPratilipiData);
                }
                const action = !this.getPratilipiData ? 'SHAREUSERTW' : 'SHAREBOOKTW';
                this.triggerAnanlyticsEvent(`${action}_${this.getScreenDetails.screen_location}_${this.getScreenDetails.screen_name}`, 'CONTROL', {
                    ...pratilipiAnalyticsData,
                    'USER_ID': this.getUserDetails.userId,
                    'ENTITY_VALUE': 'TWITTER',
                    'PARENT_ID': this.$route.params[this.$route.meta.id_prop]
                });
            },
            triggerWaShareEvent () {
                let pratilipiAnalyticsData = {};
                if (this.getPratilipiData) {
                    pratilipiAnalyticsData = this.getPratilipiAnalyticsData(this.getPratilipiData);
                }
                const action = !this.getPratilipiData ? 'SHAREUSERWA' : 'SHAREBOOKWA';
                this.triggerAnanlyticsEvent(`${action}_${this.getScreenDetails.screen_location}_${this.getScreenDetails.screen_name}`, 'CONTROL', {
                    ...pratilipiAnalyticsData,
                    'USER_ID': this.getUserDetails.userId,
                    'ENTITY_VALUE': 'WHATSAPP',
                    'PARENT_ID': this.$route.params[this.$route.meta.id_prop]
                });
            },
            ...mapActions('alert', [
                'triggerAlert'
            ])
        },
        created() {
            this.currentLocale = this.getLanguageCode(process.env.LANGUAGE);
        }
    };
</script>

<style lang="scss" scoped>
    .share-modal {
        max-width: 320px;
        margin: -196px auto 0;
        top: 50%;
        .modal-header {
            padding: 15px;
            h5.modal-title {
                font-size: 16px;
            }
        }
        .social a {
            vertical-align: middle;
            color: #fff;
            display: block;
            text-align: left;
            margin: 15px auto;
            padding: 5px 20px 5px 15px;
            font-size: 14px;
            width: 200px;
            height: 45px;
            line-height: 32px;
            border-radius: 3px;
            background: #2c3e50;
            cursor: pointer;
            .social-icon {
                display: inline-block;
                margin-right: 10px;
                vertical-align: middle;
            }
            .fa-icon, span.text {
                vertical-align: middle;
            }
            &:hover {
                text-decoration: none;
            }
        }
        .social a.whatsapp {
            background: #48C631;
        }
        .social a.facebook {
            background: #3b5998;
        }
        .social a.link {
            background: #2c3e50;
        }
        .ml-20-neg {
            margin-left: -20px;
        }
    }
</style>
